import type { IAlgoliaSearch } from './types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useRef, useContext } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';

import { ProjectContext } from '@core/context';
import useDebounced from '@core/hooks/useDebounced';

import ConfigurationWrapper from './components/ConfigurationWrapper';
import RefinementList from './components/RefinementList';
import SearchBox from './components/SearchBox';
import SearchResults from './components/SearchResults';
import SearchTabs from './components/SearchTabs';
import SearchContextProvider from './context';
import useSearch from './hooks/useSearch';
import classes from './style.module.scss';

const AlgoliaSearch = ({
  id,
  appId,
  indexName,
  isModalOpen,
  searchApiKey,
  email,
  projectsMeta,
  urlManagerOpts,
}: IAlgoliaSearch) => {
  const { project } = useContext(ProjectContext) as unknown as { project: ProjectClientSide };
  const rootNode = useRef<HTMLDivElement | null>(null);
  const searchInput = useRef<HTMLInputElement | null>(null);

  const {
    algoliaClient,
    initAlgolia,
    refinementListOpen,
    handleKeyboardEvent,
    handleRefinementBlur,
    handleResultSelection,
    handleFocusIn,
  } = useSearch({ appId, searchApiKey, email, project, rootNode, searchInput, isReady: isModalOpen });

  const handleResultSelectionDebounced = useDebounced(handleResultSelection, 100);
  const displayRefinementList = projectsMeta.length > 1;

  return (
    <SearchContextProvider
      handleKeyboardEvent={handleKeyboardEvent}
      projectMetadata={projectsMeta}
      subdomain={project?.subdomain || ''}
    >
      <InstantSearch indexName={indexName} searchClient={algoliaClient[initAlgolia]}>
        <ConfigurationWrapper />
        <div
          ref={rootNode}
          className={`${classes.AlgoliaSearch} ${displayRefinementList ? classes.AlgoliaSearch_withSidebar : ''}`}
          id={id}
          onFocus={handleFocusIn}
          role="tabpanel"
          tabIndex={0}
        >
          <div className={classes['AlgoliaSearch-Col']}>
            <SearchBox
              inputRef={searchInput}
              isModalOpen={isModalOpen}
              onClickout={handleResultSelectionDebounced}
              subdomain={project?.subdomain || ''}
            />
            <SearchTabs />
            <SearchResults
              onResultSelection={handleResultSelectionDebounced}
              projectMetadata={projectsMeta}
              urlManagerOpts={urlManagerOpts}
            />
          </div>

          {!!displayRefinementList && (
            <RefinementList
              isOpen={refinementListOpen}
              onBlur={handleRefinementBlur}
              showMoreLimit={projectsMeta?.length}
            />
          )}
        </div>
      </InstantSearch>
    </SearchContextProvider>
  );
};

export default React.memo(AlgoliaSearch);
