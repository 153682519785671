import type { IRefinementList } from './types';

import React, { useCallback, useContext } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';

import Button from '@ui/Button';
import Toggle from '@ui/Toggle';

import { SearchContext } from '../../context';

import classes from './style.module.scss';

const RefinementList = ({ onBlur, isOpen, showMoreLimit = 30 }: IRefinementList) => {
  const { toggleFacetSelection } = useContext(SearchContext);

  // Only display our "More" button (and trigger Algolia's API) if our facets are > 10
  const showMore = showMoreLimit > 10;

  const { items, refine, isShowingMore, canToggleShowMore, toggleShowMore } = useRefinementList({
    attribute: 'subdomain',
    limit: showMore ? 10 : showMoreLimit,
    showMore,
    // Show More Limit should be greater than internal limit
    showMoreLimit: showMoreLimit + 1,
    // If a facet is selected, push it to the top of the list
    // Otherwise, sort by facet name, alphabetically
    sortBy: ['isRefined:desc', 'name:asc'],
  });

  const blurred = useCallback(
    (event: React.KeyboardEvent<HTMLLIElement>) => {
      const { currentTarget, key, shiftKey } = event;
      if (key === 'Tab' && !shiftKey && currentTarget.matches(':last-of-type') && typeof onBlur === 'function')
        onBlur(event);
    },
    [onBlur],
  );

  if (items.length <= 1) return null;

  return (
    <details className={classes['RefinementList-Col']} open={isOpen}>
      <summary className={classes['RefinementList-Header']} tabIndex={-1}>
        Filters
      </summary>
      <ol className={classes.RefinementList}>
        {items.map(item => (
          <li key={item.label} className={classes['RefinementList-Item']} onKeyDown={blurred}>
            <Toggle
              checked={item.isRefined}
              className={classes['RefinementList-Toggle']}
              label={item.label}
              onChange={() => {
                refine(item.value);
                toggleFacetSelection(item.value);
              }}
            />
          </li>
        ))}
      </ol>
      {!!canToggleShowMore && (
        <Button className={classes['RefinementList-Button']} kind="minimum" onClick={toggleShowMore} size="xs" text>
          {isShowingMore ? 'Less' : 'More'}
        </Button>
      )}
    </details>
  );
};

export default React.memo(RefinementList);
