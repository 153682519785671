import type { $TSFixMe } from '@readme/iso';
import type { IAlgoliaSearch } from '@readme/react/src/Hub/Search/types';

import React, { useState, useContext, useRef, useCallback, useMemo, useEffect } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';

import ConfigurationWrapper from '@Hub/Search/components/ConfigurationWrapper';
import SearchResults from '@Hub/Search/components/SearchResults';
import SearchContextProvider from '@Hub/Search/context';
import useSearch from '@Hub/Search/hooks/useSearch';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import useMeta from '@core/hooks/useMeta';
import { useSearchStore } from '@core/store';
import { scrollToTop } from '@core/utils/ScrollTop';

import Button from '@ui/Button';
import ChatGPT from '@ui/ChatGPT';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuHeader } from '@ui/Menu';
import Spinner from '@ui/Spinner';

import Prompt from './Prompt';
import classes from './style.module.scss';

interface SearchBertProps {
  childSubdomain: string;
  isModalOpen: boolean;
  search: IAlgoliaSearch;
  toggleSearch: ({ target }: { target: Event['target'] }) => void;
}

const SearchBert = ({ isModalOpen, toggleSearch, childSubdomain, search }: SearchBertProps) => {
  const bem = useClassy(classes, 'SearchBert');
  const [prompt, setPrompt] = useSearchStore(store => [store.prompt, store.updatePrompt]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleAppendTo = useCallback(() => document.querySelector('.App'), []);
  const offset = useMemo(() => [4, 8], []);
  const { user = {} } = useContext(UserContext) as $TSFixMe;
  const { email } = user;

  const { appId, projectsMeta, urlManagerOpts, searchApiKey, indexName } = search;

  // CHAT GPT FUNCTIONALITY
  const [askQuestion, setAskQuestion] = useState(false);

  // SEARCH FUNCTIONALITY
  const [searching, setSearching] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const rootNode = useRef(null);
  const searchInput = useRef<HTMLInputElement>(null);

  const { algoliaClient, initAlgolia, handleKeyboardEvent, handleResultSelection, handleFocusIn } = useSearch({
    appId,
    searchApiKey,
    email,
    project,
    rootNode,
    searchInput,
    isReady: !isModalOpen,
  });

  const handleResultSelectionDebounced = useDebounced(handleResultSelection, 100);
  const isViewingSearchResults = !searching && !!prompt;

  const handleOnToggle = useCallback(isOpen => {
    setIsDropdownOpen(isOpen);
  }, []);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        // When a user presses 'Tab', unfocus the search input
        searchInput?.current?.blur();
      }

      const isMetaKeyCombo = e.key === 'k' && (e.ctrlKey || e.metaKey);

      if (!isMetaKeyCombo) return;

      e.preventDefault();
      if (isDropdownOpen && isViewingSearchResults) {
        toggleSearch(e);
      } else if (!isModalOpen) {
        // When a user presses 'meta-k' focus into the search input
        scrollToTop(true);
        searchInput?.current?.focus();
      }
    };

    window?.addEventListener('keydown', onKeydown);
    return () => window?.removeEventListener('keydown', onKeydown);
  }, [searchInput, isModalOpen, isDropdownOpen, isViewingSearchResults, toggleSearch]);

  // Do an includes check instead of `useMeta('k')` because of the conditional styling below
  const metaKeyCombo = useMeta().includes('⌘') ? (
    <>
      <span className={bem('-shortcut-cmd')}>⌘</span>K
    </>
  ) : (
    'Ctrl+K'
  );

  return (
    <SearchContextProvider
      handleKeyboardEvent={handleKeyboardEvent}
      projectMetadata={projectsMeta}
      subdomain={project?.subdomain || ''}
    >
      <InstantSearch indexName={indexName} searchClient={algoliaClient[initAlgolia]}>
        <ConfigurationWrapper />
        <Dropdown
          appendTo={handleAppendTo}
          clickInToClose={false}
          offset={offset}
          onToggle={handleOnToggle}
          sticky
          trigger="focus"
        >
          <Flex
            align="center"
            className={`rm-OwlbotAI ${bem('&')}`}
            gap="xs"
            justify="start"
            role="combobox"
            tag="label"
          >
            <Flex align="center" htmlFor="SearchBert">
              {isTyping ? (
                <Spinner
                  aria-label="Searching…"
                  className={`rm-OwlbotAI-spinner ${bem('-icon', '-icon_spinner')}`}
                  size="md"
                  strokeWidth={1.5}
                />
              ) : (
                <Icon aria-label="Search" className={bem('-icon')} name="search" size="md" />
              )}
            </Flex>
            <Prompt
              handleResultSelectionDebounced={handleResultSelectionDebounced}
              onFocus={handleFocusIn}
              project={project}
              prompt={prompt}
              searching={searching}
              searchInput={searchInput}
              setAskQuestion={setAskQuestion}
              setIsTyping={setIsTyping}
              setPrompt={setPrompt}
              setSearching={setSearching}
            />
            {!isDropdownOpen && !isModalOpen && (
              <kbd className={bem('-shortcut', '-shortcut_input')}>{metaKeyCombo}</kbd>
            )}
          </Flex>
          {!!isViewingSearchResults && (
            <Menu className={`${bem('-menu')} rm-OwlbotMenu`} TagName="section">
              <MenuHeader>
                <Flex align="center" justify="between" tag="header">
                  <span className={bem('-uppercase')}>From the docs</span>
                  <Button
                    circular
                    className={bem('-moreButton')}
                    kind="primary"
                    onClick={toggleSearch}
                    outline
                    size="xs"
                  >
                    <kbd className={bem('-shortcut')}>{metaKeyCombo}</kbd> for more
                  </Button>
                </Flex>
              </MenuHeader>
              <SearchResults
                className="rm-OwlbotMenu-results"
                limit={3}
                onResultSelection={handleResultSelectionDebounced}
                projectMetadata={projectsMeta}
                urlManagerOpts={urlManagerOpts}
              />
              <div className={bem('-owlbotContainer')}>
                <ChatGPT
                  askQuestion={askQuestion}
                  childSubdomain={childSubdomain}
                  project={project}
                  prompt={prompt}
                  setAskQuestion={setAskQuestion}
                />
              </div>
            </Menu>
          )}
        </Dropdown>
      </InstantSearch>
    </SearchContextProvider>
  );
};

export default SearchBert;
