import type { SearchResult, SearchSections } from '@readme/api/src/mappings/search/types';

import React, { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { IProjectMetadata } from '@Hub/Search/types';

import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import type { HTTPMethod } from '@core/types';

import APIMethod from '@ui/API/Method';
import Icon from '@ui/Icon';

import styles from './style.module.scss';

interface ResultIconProps {
  className?: string;
  section: SearchSections;
}

interface ResultExcerptProps {
  result: SearchResult;
}
interface ResultProps {
  className?: string;
  onResultSelection: ({ link }: { link: string }) => void;
  projectMetadata: IProjectMetadata[];
  result: SearchResult;
}

export const ResultIcon = ({ className, section }: ResultIconProps) => {
  const bem = useClassy(styles, 'SearchResults');

  const iconMap = useMemo(
    () =>
      new Map(
        Object.entries({
          reference: 'icon-references',
          docs: 'icon-guides',
          guides: 'icon-guides',
          recipes: 'icon-recipes',
          changelog: 'icon-changelog',
          discuss: 'icon-discussions',
          custom_pages: 'icon-custom-pages-2',
        }),
      ),
    [],
  );

  const icon = iconMap.get(section);

  if (!icon) return null;

  return <Icon className={bem('-result-icon', className && `${className}-result-icon`)} isFont name={icon} />;
};

/*
 * A simple result excerpt builder that highlights the top hits in text
 * Note: This is a simple first pass, we'll likely be refining
 * this as we fine-tune results and query matching on API side
 */
export const ResultExcerpt = ({ result }: ResultExcerptProps) => {
  const bem = useClassy(styles, 'SearchResults');

  // Get the top highlight matches (highlights are returned sorted by score)
  const topHighlight = result.highlights[0];

  // Create the highlighted excerpt
  const highlightedExcerpt = topHighlight.texts.map((text, index) =>
    text.type === 'hit' ? (
      <span key={index} className={bem('-result-excerpt-match-highlight')} data-testid="highlight-span">
        {text.value}
      </span>
    ) : (
      text.value
    ),
  );

  return (
    <div className={bem('-result-excerpt')} title={result.title}>
      <p>{highlightedExcerpt}</p>
    </div>
  );
};

const Result = ({ className, onResultSelection, projectMetadata, result }: ResultProps) => {
  const bem = useClassy(styles, 'SearchResults');
  const { project } = useContext(ProjectContext);

  const { url, section, subdomain, title, uri } = result;

  const handleArrowFocus = useCallback((e: React.KeyboardEvent<HTMLAnchorElement>) => {
    const { key, currentTarget } = e;

    if (key !== 'ArrowUp' && key !== 'ArrowDown') return;

    e.preventDefault();

    const hitList = currentTarget.parentElement;
    if (!hitList) return;

    const siblings = Array.from(hitList.children) as HTMLElement[];
    const currentIndex = siblings.indexOf(currentTarget as HTMLElement);

    let nextIndex: number;
    if (key === 'ArrowUp') {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : siblings.length - 1;
    } else {
      nextIndex = currentIndex < siblings.length - 1 ? currentIndex + 1 : 0;
    }

    siblings[nextIndex].focus();
  }, []);

  // If the result is from the same project, we can use the relative link for client side navigation
  // Otherwise we'll use absolute URL and have a full page reload on navigation
  const useRelativeLink = result.subdomain === project.subdomain;

  const commonProps = {
    className: bem('-result', className && `${className}-result`),
    onClick: () => {
      onResultSelection({ link: uri });
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => handleArrowFocus(e),
  };

  const resultContent = useMemo(() => {
    return (
      <>
        <ResultIcon className={className} section={section} />
        <div className={bem('-result-text')}>
          <header className={bem('-result-header')} title={title}>
            {!!result?.api && !!result?.api?.method && (
              <APIMethod className={bem('-method')} type={result.api.method as HTTPMethod} />
            )}
            <span className={bem('-result-title', className && `${className}-result-title`)}>
              {title}
              {projectMetadata.length > 1 && (
                <span className={bem('-result-project', className && `${className}-result-project`)}>
                  in {subdomain}
                </span>
              )}
            </span>
            {/* RM-10519 - See if we need to support external links in API v2 /search */}
            {/* {!!result.link_url && (
            <small className={classes['SearchResults-Result-Link']}>
              <i className="fa fa-external-link-alt" />
            </small>
          )} */}
          </header>
          <ResultExcerpt result={result} />
        </div>
      </>
    );
  }, [bem, className, projectMetadata, result, section, subdomain, title]);

  return useRelativeLink ? (
    <Link {...commonProps} to={url.relative}>
      {resultContent}
    </Link>
  ) : (
    <a {...commonProps} href={url.full}>
      {resultContent}
    </a>
  );
};

export default Result;
