import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppMetaContext } from '@core/context';
import useEventListener from '@core/hooks/useEventListener';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

const TOGGLE_CLASS = 'showSidebarFlyout';

const MobileSubnav = () => {
  const { pathname } = useLocation();
  const appMeta = useContext(AppMetaContext);
  const wrapperRef = useRef<HTMLElement>(null);
  const [flyoutOpen, setFlyoutOpen] = useState(false);

  const handleToggle = e => {
    // toggle mobile sidebar on button click
    e.stopPropagation();
    setFlyoutOpen(!flyoutOpen);
    document.body.classList.toggle(TOGGLE_CLASS);
  };

  useEventListener('click', e => {
    // close mobile sidebar on click outside
    const clickedInSidebar = (e.target as HTMLElement).closest('#hub-sidebar');
    const clickedOnSelf = e.target === wrapperRef.current;
    if (flyoutOpen && !clickedOnSelf && !clickedInSidebar) {
      e.preventDefault();
      setFlyoutOpen(false);
      document.body.classList.toggle(TOGGLE_CLASS, false);
    }
  });

  useEffect(() => {
    // close mobile sidebar on navigation
    setFlyoutOpen(false);
    document.body.classList.toggle(TOGGLE_CLASS, false);
  }, [pathname]);

  return (
    (pathname.startsWith('/docs') || pathname.startsWith('/reference')) && (
      <Flex
        ref={wrapperRef}
        align="center"
        className={classes.MobileSubnav}
        gap="xs"
        kind="contrast"
        onClick={handleToggle}
        outline
        size="sm"
        tag={Button}
      >
        {appMeta?.title || 'Loading…'}
        <Icon name={flyoutOpen ? 'x' : 'chevron-up-down'} />
      </Flex>
    )
  );
};

export default MobileSubnav;
