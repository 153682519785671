import type { IProjectMetadata } from './types';
import type { Options as UrlManagerOptions } from '@readme/server-shared/UrlManager';

import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import SuperHubSearch from '@Hub/SuperHubSearch';

import { ProjectContext } from '@core/context';
import { useSuperHubStore } from '@core/store';

import AppSearch from '.';

interface Props {
  appId: string;
  email: string;
  indexName: string;
  isModalOpen: boolean;
  projectsMeta: IProjectMetadata[];
  searchApiKey: string;
  toggleOpen: () => void;
  urlManagerOpts: UrlManagerOptions;
}

const SearchModal = ({
  appId,
  email,
  indexName,
  isModalOpen,
  projectsMeta,
  searchApiKey,
  toggleOpen,
  urlManagerOpts,
}: Props) => {
  const { search } = useLocation();
  const { project } = useContext(ProjectContext);
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);

  const owlbotTrial = new URLSearchParams(search).get('owlbotTrial');
  const owlbotEnabled = project.owlbot.enabled || owlbotTrial === 'true';

  useEffect(() => {
    const onKeydown = e => {
      if (e.key === 'Escape') {
        // When a user presses 'esc', close the modal if it's active
        if (isModalOpen) toggleOpen();
      }
      if (e.key === 'k' && (e.ctrlKey || e.metaKey) && !owlbotEnabled) {
        // When a user presses 'cmd-k' or 'ctrl-k', toggle the modal open or closed
        e.preventDefault();
        toggleOpen();
      }
    };

    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    window?.addEventListener('keydown', onKeydown);
    return () => window?.removeEventListener('keydown', onKeydown);
  }, [isModalOpen, owlbotEnabled, toggleOpen]);

  const id = 'AppSearch';

  return (
    <div
      className={`hub-search-results--reactApp ${isModalOpen ? 'hub-search-results-active' : ''}`}
      id="hub-search-results"
    >
      <div className="hub-container">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className="modal-backdrop show-modal rm-SearchModal"
          onClick={(event: React.MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(`#${id}`)) {
              toggleOpen();
            }
          }}
          role="button"
          tabIndex={0}
        >
          {/* SuperHub search uses Mongo Atlas instead of Algolia so we have different search subcomponents */}
          {isSuperHub ? (
            <SuperHubSearch
              email={email}
              id={id}
              isModalOpen={isModalOpen}
              projectsMeta={projectsMeta}
              toggleModal={toggleOpen}
            />
          ) : (
            !!searchApiKey && (
              <AppSearch
                appId={appId}
                email={email}
                id={id}
                indexName={indexName}
                isModalOpen={isModalOpen}
                projectsMeta={projectsMeta}
                searchApiKey={searchApiKey}
                urlManagerOpts={urlManagerOpts}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
