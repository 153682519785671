import type { ProjectDocument } from '../project/types';
import type { Document, Model } from 'mongoose';

// Subset used for logQuestion utility
interface ChatGPTMiniLog {
  aiModel: string;
  answer: string;
  defaultAnswer: string;
  project: ProjectDocument | ProjectDocument['_id'];
  prompt: string;
  question: string;
  source: 'api' | 'hub';
}

export interface ChatGPTLogSchema {
  _id: Document['_id'];

  aiModel: string;
  answer: string;
  cost: number;
  createdAt: Date;
  project: ProjectDocument | ProjectDocument['_id'];
  question: string;
  source: 'api' | 'hub';
  vote: number;
}

export enum VoteType {
  DOWN = 'down',
  UP = 'up',
}

export const VoteTypeArray = Object.values(VoteType);

interface DocumentMethods {}

interface StaticMethods {
  logQuestion({ question, answer, prompt, aiModel, project, defaultAnswer }: ChatGPTMiniLog): Promise<null>;
  showOrLoadChatGPTLogData(email: string): Promise<ChatGPTLogDocument>;
}

export interface ChatGPTLogDocument extends Document, ChatGPTLogSchema, DocumentMethods {}

export interface ChatGPTLogModel extends Model<ChatGPTLogDocument>, StaticMethods {}
